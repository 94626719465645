import "./rolescard.css";
import React, { useState } from "react";
import ITPopUp from "../../Popup/ItArchitects";

const ItArchitects = () => {

    const [showPopup, setShowPopup] = useState(false);
    const maxLength = 150;


    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
          return text.substring(0, maxLength) + "...";
        }
        return text;
    };
    
      const paragraphText =
        "For IT architects, our solution offers a scalable and flexible infrastructure that adapts to the organization's needs. We provide deployment options in both cloud and on-premises environments, allowing for hybrid or fully cloud-based implementation, depending on specific requirements. Our platform also offers security options and access control, ensuring the protection of sensitive data and models. Additionally, our solution integrates with existing tools and technologies, facilitating adoption and integration into the organization's IT ecosystem.";
    
      const truncatedParagraph = truncateText(paragraphText, maxLength);

    return(
        <div className="Rolecard-container">
            
            <div className="Rolecard-information">
                <h3 className="itarchitects-title">IT ARCHITECTS</h3>
                <p>{ truncatedParagraph }</p>
            </div>

            <button className="role-button" onClick={togglePopup}>
                learn more
            </button>

            {showPopup && <ITPopUp onClose={togglePopup} />}

        </div>
    )
};



export default ItArchitects;