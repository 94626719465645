import "./rolescard.css";
import React, { useState } from "react";
import ExecutivesPopUp from "../../Popup/Executives";

const Executives = () => {

    const [showPopup, setShowPopup] = useState(false);
    const maxLength = 150;


    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
          return text.substring(0, maxLength) + "...";
        }
        return text;
    };
    
      const paragraphText =
        "Our solution provides executives with a comprehensive and transparent view of the process of creating and automating machine learning models and datasets. Through intuitive dashboards, executives can monitor and measure model performance, track associated costs, and make data-informed decisions. Our platform also facilitates collaboration and communication among team members, enabling executives to stay informed and provide guidance throughout the model creation journey.";
    
      const truncatedParagraph = truncateText(paragraphText, maxLength);

    return(
        <div className="Rolecard-container">
            
            <div className="Rolecard-information">
                <h3 className="executives-title">EXECUTIVES</h3>
                <p>{truncatedParagraph}</p>
            </div>

            <button className="role-button" onClick={togglePopup}>
                learn more
            </button>

            {showPopup && <ExecutivesPopUp onClose={togglePopup} />}

        </div>
    )
};



export default Executives;