import "./contact.css";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import { animateScroll as scroll } from "react-scroll";
import Image from "../../assets/images/LogoFooter.png";
import Linkedin from "../../assets/images/Footer/linkedin.png";
import Component from "../../assets/images/Footer/Component.png";
import Crunchbase from "../../assets/images/Footer/crunchbase.png";
import EngineerPopUp from '../Popup/Engineer';
import DeveloperPopUp from '../Popup/Developer';
import ScientistPopUp from '../Popup/Scientist';
import ExecutivesPopUp from '../Popup/Executives';
import ArchitectsPopUp from '../Popup/ItArchitects';
import SuccessPopUp from "./SuccessPopUp";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(true);
  const [showError, setShowError] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const hasMissingFields = missingFields.length > 0;
  const [recaptchaResponse, setRecaptchaResponse] = useState("");
  const [areInputsFilled, setAreInputsFilled] = useState(false);


  const [showEngineerPopup, setEngineerShowPopup] = useState(false);
  const [showDeveloperPopup, setDeveloperShowPopup] = useState(false);
  const [showScientistPopup, setScientistShowPopup] = useState(false);
  const [showExecutivesPopup, setExecutivesShowPopup] = useState(false);
  const [showArchitectsPopup, setArchitectsShowPopup] = useState(false);

  const toggleEngineerPopup = () => {
    setEngineerShowPopup(!showEngineerPopup);
  };

  const toggleDeveloperPopup = () => {
    setDeveloperShowPopup(!showDeveloperPopup);
  };

  const toggleScientistPopup = () => {
    setScientistShowPopup(!showScientistPopup);
  };

  const toggleExecutivesPopup = () => {
    setExecutivesShowPopup(!showExecutivesPopup);
  };

  const toggleArchitectsPopup = () => {
    setArchitectsShowPopup(!showArchitectsPopup);
  };

  // SCROLL TO SECTIONS

  const scrollToHome = () => {
    scroll.scrollTo(document.getElementById("Home").offsetTop);
  };

  const scrollToProject = () => {
    scroll.scrollTo(document.getElementById("ProductPage").offsetTop);
  };

  const scrollToSolution = () => {
    scroll.scrollTo(document.getElementById("SolutionPage").offsetTop);
  };

  const scrollToApi = () => {
    scroll.scrollTo(document.getElementById("ApiPage").offsetTop);
  };

  const scrollToApiExperimentandDeploy = () => {
    scroll.scrollTo(document.getElementById("ApiPage").offsetTop);
  };

  const scrollToApiDeployandMonitor = () => {
    scroll.scrollTo(document.getElementById("ApiExperiment").offsetTop);
  };

  const scrollToIntegrations = () => {
    scroll.scrollTo(document.getElementById("IntegrationsPage").offsetTop);
  };

  const scrollToWho = () => {
    scroll.scrollTo(document.getElementById("whoPage").offsetTop);
  };

  const scrollToWhoAbout = () => {
    scroll.scrollTo(document.getElementById("whoPage").offsetTop);
  };

  const scrollToWhoMission = () => {
    scroll.scrollTo(document.getElementById("MisionAndVision").offsetTop);
  };

  const handleClickHome = () => {
    scrollToHome();
  };

  const handleClickProduct = () => {
    scrollToProject();
  };

  const handleClickEngineerSolution = () => {
    scrollToSolution();
    toggleEngineerPopup();
  };

  const handleClickScientistSolution = () => {
    scrollToSolution();
    toggleScientistPopup();
  };

  const handleClickDeveloperSolution = () => {
    scrollToSolution();
    toggleDeveloperPopup();
  };

  const handleClickExecutivesSolution = () => {
    scrollToSolution();
    toggleExecutivesPopup();
  };

  const handleClickITSolution = () => {
    scrollToSolution();
    toggleArchitectsPopup();
  };

  const handleClickApi = () => {
    scrollToApi();
  };

  const handleClickIntegrations = () => {
    scrollToIntegrations();
  };

  const handleClickWho = () => {
    scrollToWho();
  };

  const isFormValid = email.trim() !== "" && message.trim() !== "";
  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isFormValid && isValidEmail && recaptchaResponse !== "") {
      try {
        const response = await axios.post(
          process.env.REACT_APP_URL_API_MSG,
          {
            message: {
              email: email,
              content: message
            }
          },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_CONV_API_KEY,
            },
          }
        );

        if (response.data.status === "MESSAGE_SENT") {
          setIsSuccessPopupVisible(false);
          setShowSuccessPopup(true);
          setEmail("");
          setMessage("");
          setMissingFields([]);
          setShowError(false);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setShowError(true);
      setMissingFields([
        ...(email.trim() === "" ? ["email"] : []),
        ...(message.trim() === "" ? ["message"] : []),
        ...(recaptchaResponse === "" ? ["recaptcha"] : []),
      ]);
    }
  };

  const handelClickOpenPopUp = () => {
    setShowSuccessPopup(true);
  }

  const handleClickLinkedIn = () => {
    window.open("https://www.linkedin.com/company/convolutionai/about/");
  };

  const handleClickCrunchBase = () => {
    window.open("https://www.crunchbase.com/organization/convolution-ai");
  };


  const handleCloseSuccessPopup = () => {
    setShowSuccessPopup(false);
    setIsSuccessPopupVisible(true);
  };

  const checkInputsFilled = () => {
    const inputsFilled = email.trim() !== "" && message.trim() !== "";
    setAreInputsFilled(inputsFilled);
  };

  useEffect(() => {
    checkInputsFilled();
  }, [email, message]);

  const adjustTextareaHeight = () => {
    const textarea = document.getElementById("message");
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  };
  
  useEffect(() => {
    adjustTextareaHeight();
  }, [message]);
  
  const handleTextareaChange = (e) => {
    setMessage(e.target.value);
    adjustTextareaHeight();
  };


  return (
    <div id="contactPage" className="contact">
      <div className="contact-container">
        <div className="ContactTitlecontainer">
          <h2>Contact <span>us</span></h2>
          <p>
            For any inquiries, partnership opportunities, or to learn more about our AI solutions, feel free to reach
            out.
            <br />
            <br />
            We look forward to hearing from you and assisting you on your AI journey.
          </p>
        </div>

        <form className="form-contact" onSubmit={handleSubmit}>
          <div className={`input-container ${hasMissingFields && "error"}`}>
            <input
              placeholder="E-MAIL"
              className="input-email"
              type="email"
              id="email"
              minLength='10'
              required={true}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={`input-container ${hasMissingFields && "error"}`}>
          <textarea
            className={`textarea-m ${hasMissingFields && "error"}`}
            placeholder="MESSAGE"
            id="message"
            required={true}
            value={message}
            minLength="5"
            onChange={(e) => setMessage(e.target.value)}
          />
          </div>

          {message.length > 4 && isSuccessPopupVisible && (
            <div className="recaptcha-container">
              <ReCAPTCHA
                sitekey="6LcxGxMnAAAAAN7rV6q-78qHQU76nTlJmWJRhx-A"
                onChange={(value) => setRecaptchaResponse(value)}
              />
            </div>
          )}

          {hasMissingFields && (
            <div className="recaptcha-error">
              {missingFields.includes("recaptcha")
                ? "Please complete the reCAPTCHA."
                : "Please fill in all required fields."}
            </div>
          )}

          <div className="submitbutton-pos">
            <button className="button-submition" disabled={!isFormValid}>
              SUBMIT
            </button>
          </div>
          {/* <button onClick={handelClickOpenPopUp}>OPEN</button> */}
        </form>

        {showSuccessPopup && (
          <SuccessPopUp onClose={handleCloseSuccessPopup} />
        )}
      </div>

      <footer className="Footercontact">
        <img onClick={handleClickHome} className="Footer-logo" src={Image} alt="" />

        <div className="allsections">
          <div className="footer-section">
            <h2 onClick={handleClickProduct}>PRODUCT</h2>
          </div>

          <div className="footer-section">
            <h2 onClick={scrollToSolution}>SOLUTION</h2>

            <ul className="footer-list">
              <li onClick={handleClickEngineerSolution}>Data Engineers
                {showEngineerPopup && <EngineerPopUp onClose={toggleEngineerPopup} />}
              </li>
              <li onClick={handleClickDeveloperSolution}>Developer
                {showDeveloperPopup && <DeveloperPopUp onClose={toggleDeveloperPopup} />}
              </li>
              <li onClick={handleClickITSolution}>It Architects
                {showArchitectsPopup && <ArchitectsPopUp onClose={toggleArchitectsPopup} />}
              </li>
              <li onClick={handleClickScientistSolution}>Data Scientist
                {showScientistPopup && <ScientistPopUp onClose={toggleScientistPopup} />}
              </li>
              <li onClick={handleClickExecutivesSolution}>Executives
                {showExecutivesPopup && <ExecutivesPopUp onClose={toggleExecutivesPopup} />}
              </li>
            </ul>
          </div>

          <div className="footer-section">
            <h2 onClick={handleClickApi}>API</h2>

            <ul className="footer-list">
              <li onClick={scrollToApiExperimentandDeploy}>Integrate</li>
              <li onClick={scrollToApiExperimentandDeploy}>Experiment</li>
              <li onClick={scrollToApiDeployandMonitor}>Deploy</li>
              <li onClick={scrollToApiDeployandMonitor}>Monitor</li>
            </ul>
          </div>

          <div className="footer-section">
            <h2 onClick={handleClickIntegrations}>INTEGRATE</h2>
          </div>

          <div className="footer-section">
            <h2 onClick={handleClickWho}>WHO WE ARE?</h2>

            <ul className="Who-list">
              <li onClick={scrollToWhoAbout}>About Us</li>
              <li onClick={scrollToWhoMission}>Mission and Vision</li>
            </ul>
          </div>
        </div>

        <div className="icon-container-footer">
          <img onClick={handleClickLinkedIn} className="Footer-icon" alt="" src={Linkedin} />
          <img className="Footer-icon" alt="" src={Component} />
          <img onClick={handleClickCrunchBase} className="Footer-icon" alt="" src={Crunchbase} />
          <p>info@convolution-ai.com</p>
        </div>
      </footer>
    </div>
  );
};

export default Contact;
