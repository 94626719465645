import "./productcard.css";
import React from "react";
import Photo from '../../../assets/images/Solutions/versioning.svg'

const Versioning = () => {

    return(
        <div className="Productcard-container">

            <div className="Image-container">
                <img src={ Photo } alt='' style={{ width: '80%', 'margin-top': '0%', 'margin-right': '0%' }}/>
            </div>

            <div className="Productcard-information">
                <h3>VERSIONING</h3>
                <p>
                    Manag, track, compare and revert multiple versions of your models.
                </p>
            </div>
        </div>
    )
};



export default Versioning;