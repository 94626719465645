import React, { useState } from "react";
import DeveloperPopUp from "../../Popup/Developer";

const Developer = () => {
  
  const [showPopup, setShowPopup] = useState(false);
  const maxLength = 150;

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const paragraphText =
    "For developers, our solution offers a collaborative and scalable environment that enables quick and easy implementation and deployment of machine learning models. Our platform provides popular machine learning libraries and frameworks, as well as development and testing tools. Developers can access pre-trained models and use them as a basis for their own applications, accelerating development time. Furthermore, our solution facilitates the integration of models into existing systems through APIs and web services, allowing deployment in different environments and platforms.";

  const truncatedParagraph = truncateText(paragraphText, maxLength);

  return (
    <div className="Rolecard-container">
      <div className="Rolecard-information">
        <h3 className="developer-title">DEVELOPER</h3>
        <p>{truncatedParagraph}</p>
      </div>

      <button className="role-button" onClick={togglePopup}>
        learn more
      </button>

      {showPopup && <DeveloperPopUp onClose={togglePopup} />}
    </div>
  );
};

export default Developer;