import "./productcard.css";
import React from "react";
import Photo from '../../../assets/images/Solutions/train.svg'

const Train = () => {

    return(
        <div className="Productcard-container">

            <div className="Image-container">
                <img src={ Photo } alt='' style={{ width: '75%', 'margin-top': '-6%', 'margin-right': '0%' }}/>
            </div>

            <div className="Productcard-information">
                <h3>TRAIN</h3>
                <p>
                    Train and refine your custom machine learning models and achieve optimal results tailored to your specific requirements.
                </p>
            </div>
        </div>
    )
};



export default Train;