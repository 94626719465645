import "./productcard.css";
import React from "react";
import Photo from '../../../assets/images/Solutions/monitor.svg'

const Monitor = () => {

    return(
        <div className="Productcard-container">

            <div className="Image-container">
                <img src={ Photo } alt='' style={{ width: '70%', 'margin-top': '1%', 'margin-right': '11%' }}/>
            </div>

            <div className="Productcard-information">
                <h3>MONITOR</h3>
                <p>
                    Ensure accuracy and quality by continuously tracking and monitoring real-time alerts for model performance.
                </p>
            </div>
        </div>
    )
};



export default Monitor;