import "./productcard.css";
import React from "react";
import Photo from '../../../assets/images/Solutions/lab.svg'

const Test = () => {

    return(
        <div className="Productcard-container">
            
            <div className="Image-container">
                <img src={ Photo } alt='' style={{ width: '50%', 'margin-top': '2%', }}/>
            </div>
            
            <div className="Productcard-information">
                <h3>TEST</h3>
                <p>
                    Assess, validate and test your machine learning models before deploying them for practical use.
                </p>
            </div>
        </div>
    )
};



export default Test;