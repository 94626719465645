import { Component } from "react";
import Integrations from "../../components/Integrations";


export default class IntegrationsPage extends Component {

    render() {
        return (
            <div id='IntegrationsPage'>
                <Integrations/>    
            </div>
        )
    }

}