import './integrations.css';
import React, { useState } from 'react';
import { BiCaretDown, BiCaretUp  } from "react-icons/bi";
import { IoSearchSharp } from "react-icons/io5";


const integrationsArray = [
    {   
        nombre: 'Apache Airflow', 
        imagen: require("../../assets/images/Integration/Airflow.png"), 
        class: 'Data Engineering' 
    },

    { 
        nombre: 'Alteryx', 
        imagen: require("../../assets/images/Integration/alteryx.png"), 
        class: 'Data Engineering' 
    },

    { 
        nombre: 'Amazon S3', 
        imagen: require("../../assets/images/Integration/Amazon-S3.png"), 
        class: 'Data' 
    },

    { 
        nombre: 'Amazon SageMaker', 
        imagen: require("../../assets/images/Integration/Amazon-SageMaker.png"), 
        class: 'Training' 
    },
    
    { 
        nombre: 'Anaconda', 
        imagen: require("../../assets/images/Integration/Anaconda.png"), 
        class: 'Code' 
    },

    { 
        nombre: 'Apache Spark MLib', 
        imagen: require("../../assets/images/Integration/Apache-Spark-MLlib.png"), 
        class: 'Code' 
    },

    { 
        nombre: 'Apache Nifi', 
        imagen: require("../../assets/images/Integration/Apache-Nifi.png"), 
        class: 'Data Engineering' 
    },
    
    { 
        nombre: 'ArcGIS', 
        imagen: require("../../assets/images/Integration/ArcGis.png"), 
        class: 'APPS' 
    },
    
    { 
        nombre: 'Atlassian', 
        imagen: require("../../assets/images/Integration/Atlassian.png"), 
        class: 'APPS' 
    },
    
    { 
        nombre: 'Amazon Web Services', 
        imagen: require("../../assets/images/Integration/AWS.png"), 
        class: 'Infraestructure' 
    },
        
    { 
        nombre: 'BigMl', 
        imagen: require("../../assets/images/Integration/BigML.png"), 
        class: 'Training' 
    },
    
    { 
        nombre: 'Circleci', 
        imagen: require("../../assets/images/Integration/CircleCI.png"), 
        class: 'Data Engineering' 
    },
    
    { 
        nombre: 'C Sharp', 
        imagen: require("../../assets/images/Integration/CSharp.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Artboard', 
        imagen: require("../../assets/images/Integration/Artboard-48.png"), 
        class: 'APPS' 
    },
    
    { 
        nombre: 'Docker', 
        imagen: require("../../assets/images/Integration/Docker.png"), 
        class: 'Data Engineering' 
    },
    
    { 
        nombre: 'Elastic', 
        imagen: require("../../assets/images/Integration/Elastic.png"), 
        class: 'Data' 
    },
    
    { 
        nombre: 'Fastai', 
        imagen: require("../../assets/images/Integration/Fast-AI.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Github Actions', 
        imagen: require("../../assets/images/Integration/Githubactions.png"), 
        class: 'Data Engineering' 
    },
    
    { 
        nombre: 'Go', 
        imagen: require("../../assets/images/Integration/Go.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Google Cloud', 
        imagen: require("../../assets/images/Integration/Google-Cloud.png"), 
        class: 'Infraestructure' 
    },
    
    { 
        nombre: 'Java', 
        imagen: require("../../assets/images/Integration/Java.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Java Database Connectivity', 
        imagen: require("../../assets/images/Integration/Java-JDBC.png"), 
        class: 'Data' 
    },

    { 
        nombre: 'Javascript', 
        imagen: require("../../assets/images/Integration/JavaScript.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Jenkins', 
        imagen: require("../../assets/images/Integration/Jenkins.png"), 
        class: 'Data Engineering'
    },
    
    { 
        nombre: 'Julia', 
        imagen: require("../../assets/images/Integration/Julia.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Jupyter', 
        imagen: require("../../assets/images/Integration/Jupyter.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Keras', 
        imagen: require("../../assets/images/Integration/Keras.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Kubeflow', 
        imagen: require("../../assets/images/Integration/Kubeflow.png"), 
        class: 'Training' 
    },
    
    { 
        nombre: 'Kubernetes', 
        imagen: require("../../assets/images/Integration/Kubernetes.png"), 
        class: 'Data Engineering' 
    },
    
    { 
        nombre: 'Label Studio', 
        imagen: require("../../assets/images/Integration/Label-studio.png"), 
        class: 'Data Engineering'
    },
    
    { 
        nombre: 'Light GBM', 
        imagen: require("../../assets/images/Integration/LightGBM.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Azure', 
        imagen: require("../../assets/images/Integration/Microsoft_Azure.png"),
        class: 'Infraestructure' 
    },
    
    { 
        nombre: 'Microsoft Azure Blob Storage', 
        imagen: require("../../assets/images/Integration/microsoft-azure-blob.png"), 
        class: 'Data' 
    },
    
    { 
        nombre: 'Microsoft Office', 
        imagen: require("../../assets/images/Integration/Microsoft-Office.png"), 
        class: 'APPS' 
    },
    
    { 
        nombre: 'MlFlow', 
        imagen: require("../../assets/images/Integration/MlFlow.png"), 
        class: 'Training' 
    },
    
    { 
        nombre: 'Mxnet', 
        imagen: require("../../assets/images/Integration/Mxnet.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'NetApp', 
        imagen: require("../../assets/images/Integration/NetApp.png"), 
        class: 'Data' 
    },
    
    { 
        nombre: 'NumPy', 
        imagen: require("../../assets/images/Integration/NumPY.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Nvidia Cuda', 
        imagen: require("../../assets/images/Integration/Nvidia-cuda.png"), 
        class: ['Infraestructure','Code']
    },
    
    { 
        nombre: 'Onnx', 
        imagen: require("../../assets/images/Integration/ONNX.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Pandas', 
        imagen: require("../../assets/images/Integration/Panda.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Python', 
        imagen: require("../../assets/images/Integration/Python.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Pytorch', 
        imagen: require("../../assets/images/Integration/PyTorch.png"), 
        class: 'Code' 
    },

    { 
        nombre: 'Qgis', 
        imagen: require("../../assets/images/Integration/Qgis.png"), 
        class: 'APPS' 
    },
    
    { 
        nombre: 'Qlik Q', 
        imagen: require("../../assets/images/Integration/QlinkQ.png"), 
        class: 'APPS' 
    },
    
    { 
        nombre: 'R', 
        imagen: require("../../assets/images/Integration/R.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'RStudio', 
        imagen: require("../../assets/images/Integration/Rstudio.png"), 
        class: 'Data' 
    },
    
    { 
        nombre: 'Salesforce', 
        imagen: require("../../assets/images/Integration/Salesforce.png"), 
        class: 'APPS' 
    },
    
    { 
        nombre: 'SAP', 
        imagen: require("../../assets/images/Integration/SAP_Concur.png"), 
        class: 'APPS' 
    },

    { 
        nombre: 'Scala', 
        imagen: require("../../assets/images/Integration/scala.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Scikit Learn', 
        imagen: require("../../assets/images/Integration/SciKit-Learn.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'SignalFx', 
        imagen: require("../../assets/images/Integration/SignalFX.png"), 
        class: 'Data Engineering' 
    },
    
    { 
        nombre: 'Slack', 
        imagen: require("../../assets/images/Integration/slack.png"), 
        class: 'APPS' 
    },
    
    { 
        nombre: 'Snowflake', 
        imagen: require("../../assets/images/Integration/Snowflake.png"), 
        class: 'Data' 
    },
    
    { 
        nombre: 'SpaCy', 
        imagen: require("../../assets/images/Integration/Spacy.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Splunk', 
        imagen: require("../../assets/images/Integration/Splunk.png"), 
        class: 'APPS' 
    },
    
    { 
        nombre: 'Tableau', 
        imagen: require("../../assets/images/Integration/Tableau.png"), 
        class: 'APPS' 
    },

    { 
        nombre: 'TenserFlow', 
        imagen: require("../../assets/images/Integration/Tenserflow.png"), 
        class: 'Code' 
    },

    
    { 
        nombre: 'DMLC XGBoost', 
        imagen: require("../../assets/images/Integration/XGBoost.png"), 
        class: 'Code' 
    },

  ];



const Integrations = () => {

    const [searchTerm, setSearchTerm] = useState("");
    const [showCleanButton, setShowCleanButton] = useState(false);
    const [showAllImages1, setShowAllImages1] = useState(false);
    const [showAllImages2, setShowAllImages2] = useState(false);
    const [showAllImages3, setShowAllImages3] = useState(false);
    const [showAllImages4, setShowAllImages4] = useState(false);
    const [showAllImages5, setShowAllImages5] = useState(false);
    const [showAllImages6, setShowAllImages6] = useState(false);


    const handleInputChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        setShowCleanButton(value !== '');
    };
    
      const handleCleanSearch = () => {
        setSearchTerm('');
        setShowCleanButton(false);
    };

    const toggleImagesDataEngenieer = () => {
        setShowAllImages1(!showAllImages1);
    };

    const toggleImagesInfraestructure = () => {
        setShowAllImages2(!showAllImages2);
    };

    const toggleImagesData = () => {
        setShowAllImages3(!showAllImages3);
    };

    const toggleImagesCode = () => {
        setShowAllImages4(!showAllImages4);
    };

    const toggleImagesApp = () => {
        setShowAllImages5(!showAllImages5);
    };

    const toggleImagesTraining = () => {
        setShowAllImages6(!showAllImages6);
    };

    
    const filteredIntegrations = integrationsArray.filter((integration) => {
        const searchTermLower = searchTerm.toLowerCase();
        const integrationNameLower = integration.nombre.toLowerCase();
        return integrationNameLower.startsWith(searchTermLower) || integrationNameLower.includes(` ${searchTermLower}`);
    });


    const renderIntegrationCards = (integrations) => {
        return integrations.length ? (
        integrations.map((integration, index) => (
            <div className="IntegrationCard" key={index}>
                <img src={integration.imagen} alt={integration.nombre}/>
            </div>
        ))
        ) : (
            <div className="NotFound">
                <p>INTEGRATION <span>NOT FOUND</span></p>
            </div>
        );
    };

    

    const integrationDataEngineer = integrationsArray?.filter(integration => integration?.class === "Data Engineering")
    const visibleImagesDataE = showAllImages1 ? integrationDataEngineer : integrationDataEngineer.slice(0, 4);

    const integrationInfraestructure = integrationsArray?.filter(integration => integration?.class.includes("Infraestructure"));
const visibleImagesInfraestructure = showAllImages2 ? integrationInfraestructure : integrationInfraestructure.slice(0, 4);


    const integrationData = integrationsArray?.filter(integration => integration?.class === "Data")
    const visibleImagesData = showAllImages3 ? integrationData : integrationData.slice(0, 4);

    const integrationCode = integrationsArray?.filter(integration => integration?.class.includes("Code"));
    const visibleImagesCode = showAllImages4 ? integrationCode : integrationCode.slice(0, 4);

    const integrationApps = integrationsArray?.filter(integration => integration?.class === "APPS")
    const visibleImagesApps = showAllImages5 ? integrationApps : integrationApps.slice(0, 4);

    const integrationTraining = integrationsArray?.filter(integration => integration?.class === "Training")
    const visibleImagesTraining = showAllImages6 ? integrationTraining : integrationTraining.slice(0, 4);


    return (
        <div  className='Integrations-Container'>
            
            <div className='title-search-container'>
                
                <h2>Our <span>integrations</span></h2>

                <span className='searchbar-container'>

                    <IoSearchSharp className='searchbaricon'/>

                    <input
                        className='searchBar'
                        type="text"
                        value={searchTerm}
                        onChange={handleInputChange}
                    />

                    {showCleanButton && (
                        <button className='cleanSearchButton' onClick={handleCleanSearch}>
                            X
                        </button>
                    )}

                </span>

            </div>


        {searchTerm ? (

        <div className='Search-Row'>

            <h2>Your Search</h2>

            <div className='YourSearchContainer'>
                {renderIntegrationCards(filteredIntegrations)}
            </div>
        </div>

        ) : (

        <div className='Row-Container'>
            
            <div className='DataEngineering-Row'>
                
                <h2>Data Engineering</h2>
               
                <div className='DataEngineerContainer'>
                    {visibleImagesDataE.map((integration, index) => {
                        return (
                            <div className='DataEngineerCards' key={index}>
                                <img
                                    src={integration.imagen}
                                    alt={integration.nombre}
                                />
                            </div>
                        );
                        
                    })}
                </div>
                    
                <div className='ButtonIntegrationContainer'>   
                    {integrationDataEngineer.length > 4 && (
                        <button className='ButtonIntegration' onClick={toggleImagesDataEngenieer}>
                            {showAllImages1 ? <span>less<BiCaretUp className='arrowUpbutton'/></span> : <span>more<BiCaretDown className='arrowDownbutton'/></span>}
                        </button>
                    )}
                </div>

            </div>

            <div className='Infraestructure-Row'>
                
                <h2>Infraestructure</h2>
               
                <div className='InfraestructureContainer'>
                    
                    {visibleImagesInfraestructure.map((integration, index) => {
                        return (
                            <div className='InfraestructureCards' key={index}>
                                <img
                                    src={integration.imagen}
                                    alt={integration.nombre}
                                />
                            </div>
                        );
                    })}
                        
                </div>
                    
                <div className='ButtonIntegrationContainer'>   
                    {integrationInfraestructure.length > 4 && (
                        <button className='ButtonIntegration' onClick={toggleImagesInfraestructure}>
                             {showAllImages2 ? <span>less<BiCaretUp className='arrowUpbutton'/></span> : <span>more<BiCaretDown className='arrowDownbutton'/></span>}
                        </button>
                    )}
                </div>

            </div>

            <div className='Data-Row'>
                
                <h2>Data</h2>
               
                <div className='DataContainer'>
                    {visibleImagesData.map((integration, index) => {
                        return (
                            <div className='DataCards' key={index}>
                                <img
                                    src={integration.imagen}
                                    alt={integration.nombre}
                                />
                            </div>
                        );
                        
                    })}
                </div>
                    
                <div className='ButtonIntegrationContainer'>   
                    {integrationData.length > 4 && (
                        <button className='ButtonIntegration' onClick={toggleImagesData}>
                            {showAllImages3 ? <span>less<BiCaretUp className='arrowUpbutton'/></span> : <span>more<BiCaretDown className='arrowDownbutton'/></span>}
                        </button>
                    )}
                </div>

            </div>
            
            <div className='Code-Row'>
                
                <h2>Code</h2>
               
                <div className='CodeContainer'>
                    {visibleImagesCode.map((integration, index) => {
                        return (
                            <div className='CodeCards' key={index}>
                                <img
                                    src={integration.imagen}
                                    alt={integration.nombre}
                                />
                            </div>
                        );
                        
                    })}
                </div>
                    
                <div className='ButtonIntegrationContainer'>   
                    {integrationCode.length > 4 && (
                        <button className='ButtonIntegration' onClick={toggleImagesCode}>
                            {showAllImages4 ? <span>less<BiCaretUp className='arrowUpbutton'/></span> : <span>more<BiCaretDown className='arrowDownbutton'/></span>}
                        </button>
                    )}
                </div>

            </div>

            <div className='Apps-Row'>
                
                <h2>APPS</h2>
               
                <div className='AppsContainer'>
                    {visibleImagesApps.map((integration, index) => {
                        return (
                            <div className='AppsCards' key={index}>
                                <img
                                    src={integration.imagen}
                                    alt={integration.nombre}
                                />
                            </div>
                        );
                        
                    })}
                </div>
                    
                <div className='ButtonIntegrationContainer'>   
                    {integrationApps.length > 4 && (
                        <button className='ButtonIntegration' onClick={toggleImagesApp}>
                            {showAllImages5 ? <span>less<BiCaretUp className='arrowUpbutton'/></span> : <span>more<BiCaretDown className='arrowDownbutton'/></span>}
                        </button>
                    )}
                </div>

            </div>

            <div className='Training-Row'>
                
                <h2>Training</h2>
               
                <div className='TrainingContainer'>
                    {visibleImagesTraining.map((integration, index) => {
                        return (
                            <div className='TrainingCards' key={index}>
                                <img
                                    src={integration.imagen}
                                    alt={integration.nombre}
                                />
                            </div>
                        );
                        
                    })}
                </div>
                    
                <div className='ButtonIntegrationContainer'>   
                    {integrationTraining.length > 4 && (
                        <button className='ButtonIntegration' onClick={toggleImagesTraining}>
                            {showAllImages6 ? <span>less<BiCaretUp className='arrowUpbutton'/></span> : <span>more<BiCaretDown className='arrowDownbutton'/></span>}
                        </button>
                    )}
                </div>

            </div>
        </div>

        )}

        </div>
    )
}

export default Integrations;