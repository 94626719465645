import "./productcard.css";
import React from "react";
import Photo from '../../../assets/images/Solutions/deploy.svg'

const Deploy = () => {

    return(
        <div className="Productcard-container">

            <div className="Image-container">
                <img src={ Photo } alt='' style={{ width: '85%', 'margin-top': '8%', 'margin-right': '0%' }}/>
            </div>

            <div className="Productcard-information">
                <h3>DEPLOY</h3>
                <p>
                    Seamlessly implement and deploy your machine learning models for immediate utilization in the cloud or on servers.
                </p>
            </div>
        </div>
    )
};



export default Deploy;