import "./rolescard.css";
import React, { useState } from "react";
import ScientistPopUp from "../../Popup/Scientist";

const DataScientist = () => {

    const [showPopup, setShowPopup] = useState(false);
    const maxLength = 150;


    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
          return text.substring(0, maxLength) + "...";
        }
        return text;
    };
    
      const paragraphText =
        "Our platform is specifically designed to meet the needs of data scientists. We offer a workspace where data scientists can explore and analyze data, experiment with different machine learning algorithms and techniques, and train and evaluate models. Additionally, we provide a wide range of data visualization and analysis tools, making it easier to understand and interpret results. Our solution also enables collaboration and knowledge sharing among data scientists, fostering innovation and continuous improvement in the model creation process.";
    
      const truncatedParagraph = truncateText(paragraphText, maxLength);
    return(
        <div className="Rolecard-container">
            
            <div className="Rolecard-information">
                <h3 className="scientist-title">DATA SCIENTIST</h3>
                <p>{truncatedParagraph}</p>
            </div>

            <button className="role-button" onClick={togglePopup}>
                learn more
            </button>

            {showPopup && <ScientistPopUp onClose={togglePopup} />}

        </div>
    )
};



export default DataScientist;