import "./productcard.css";
import React from "react";
import Photo from '../../../assets/images/Solutions/centralize.svg'

const Centralize = () => {

    return(
        <div className="Productcard-container">

            <div className="Image-container">
                <img src={ Photo } alt='' style={{ width: '90%', 'margin-top': '-15%', 'margin-right': '0%' }}/>
            </div>

            <div className="Productcard-information">
                <h3>CENTRALIZE</h3>
                <p>
                    Store, manage and organize all your machine learning models in a centralized repository.                </p>
            </div>
        </div>
    )
};



export default Centralize;