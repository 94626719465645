import '../popup.css'
import React from "react";
import { Modal } from "reactstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";

function EngineerPopUp(props) {
  const modalStyles = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 100,
    width: window.innerWidth <= 790 ? "80%" : "auto",
  };

  const handleClose = () => {
    props.onClose();
  };

  return (
    <>
      <Modal isOpen={true} style={modalStyles} toggle={handleClose} backdrop={true}>
        <div className="PopUp-Header">
          <h2>DATA ENGINEER</h2>
          <AiOutlineCloseCircle className='Close-ButtonPopup' onClick={handleClose}/>
        </div>

        <div className="PopUp-Body">
          <p>
            Our platform provides data engineers with the necessary tools to manage the entire pipeline of creating and automating machine learning models and datasets.
            <br/>
            <br/>
            With our solution, data engineers can access an intuitive and user-friendly interface that allows them to efficiently collect, clean, transform, and prepare data.
            <br/>
            <br/>
            We also offer integration options with different data sources and systems, making it easy to process and ingest large volumes of information.
            <br/>
            <br/>
            Additionally, our platform enables the automation of repetitive tasks and the scheduling of workflows, freeing up time for data engineers to focus on more strategic tasks.
          </p>
        </div>
      </Modal>
    </>
  );
}

export default EngineerPopUp;
