import './whoweare.css';
import React from 'react';
import Img from '../../assets/images/Whoweare/Lego2.png';
import Glass from '../../assets/images/Whoweare/glasses.png';
import Flag from '../../assets/images/Whoweare/flag.png'


const WhoWeAre = () => {
    return (
        <div  className='WhoWeAre-container'>
            <div className='about-container'>
                <div className='about-title'>

                    <h2>About <span>ConvolutionAI</span></h2>

                    <p>
                        At <span>ConvolutionAI</span>, we are a team of passionate AI enthusiasts dedicated to revolutionizing the way businesses leverage artificial intelligence. Our cutting-edge technology and innovative solutions empower organizations to harness the full potential of AI and transform their operations
                    </p>

                </div>

                <div className='about-img'>
                    <img alt='' src={Img}/>
                </div>
            </div>
            
            <span id='MisionAndVision' ></span>

            <div className='Mission-container'>

                <h2><span>Mission</span> & Vision</h2>

                <div className='whocard-container'>
                    
                    <div className='mission-card'>
                        <div>

                            <div className='Flag-img'>
                                <img src={Flag} alt=''/>
                            </div>

                            <p>
                                <span>Our mission</span> is to solve the key challenges faced by organizations in managing and maximizing the value of their machine learning workflows. 
                                <br/><br/>
                                We aim to provide a comprehensive platform that streamlines model and dataset organization, ensures consistent implementation, facilitates maintenance and collaboration, and ultimately enhances the quality and outcomes of AI-based products and services. 
                            </p>

                        </div>

                    </div>

                    <div className='vision-card'>
                        <div>
                            <div className='Glass-img'>
                                <img src={Glass} alt=''/>
                            </div>

                            <p>
                                <span>Our vision</span> is to become the go-to solution for enterprises seeking to optimize their machine learning endeavors.
                                <br/><br/>
                                We strive to empower organizations with a robust web platform that simplifies ML workflow management, fosters collaboration, and drives innovation. 
                                <br/><br/>
                                By delivering unparalleled value and continuously improving our offerings, we aim to revolutionize the AI landscape and enable businesses to unlock the full potential of machine learning.
                            </p>
                        </div>
                    </div>

                </div>

            </div>

        </div>
     )
}

export default WhoWeAre;