import '../popup.css'
import React from "react";
import { Modal } from "reactstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";

function ExecutivesPopUp(props) {
  const modalStyles = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 100,
    width: window.innerWidth <= 790 ? "80%" : "auto",
  };

  const handleClose = () => {
    props.onClose();
  };

  return (
    <>
      <Modal isOpen={true} style={modalStyles} toggle={handleClose} backdrop={true}>
        <div className="PopUp-Header">
          <h2>EXECUTIVES</h2>
          <AiOutlineCloseCircle className='Close-ButtonPopup' onClick={props.onClose} />
        </div>

        <div className="PopUp-Body">
          <p>
            Our solution provides executives with a comprehensive and transparent view of the process of creating and automating machine learning models and datasets. 
            <br/>
            <br/>
            Through intuitive dashboards, executives can monitor and measure model performance, track associated costs, and make data-informed decisions. 
            <br/>
            <br/>
            Our platform also facilitates collaboration and communication among team members, enabling executives to stay informed and provide guidance throughout the model creation journey.
          </p>
        </div>
      </Modal>
    </>
  );
}

export default ExecutivesPopUp;
