import './apis.css';
import React from 'react';
import Integrate from '../../assets/images/Apis/integrate.png';
import Experiment from '../../assets/images/Apis/experiment.png';
import Deploy from '../../assets/images/Apis/deploy.png';
import Monitor from '../../assets/images/Apis/monitor.png'

const Apis = () => {
    return (
        <div className='Api-Container'>
            
            <div className='Api-title'>
                <h2>Easy & accessible <span>APIs</span></h2>
                <p>
                    Unlock The Power Of Seamless Integration, Accelerated Experimentation, Scalable
                    <br/>
                    Deployment, And Comprehensive Monitoring With Our Robust API.
                </p>
            </div>

            <div className='Apis-Section'>
                
                <div className='Api-type'>

                    <img className='ApiImg' src={Integrate} alt='' />
                    
                    <div id='ApiIntegrate' className='Api-type1'>
                        <h3>INTEGRATE</h3>
                    
                        <p>
                            Effortless integration establishment between your 
                            machine learning models & databases and our platform. Take advantage of pre-built connectors tailored for fast integration with popular software solutions.
                        </p>
                    </div>
                </div>

                <div id='ApiExperiment' className='Api-type'>

                    <img className='ApiImg' src={Experiment} alt='' />   

                    <div className='Api-type2'>
                        <h3>EXPERIMENT</h3>
                    
                        <p>
                            Accelerate your machine learning experimentation process with us. Leverage our platform's capabilities to easily test and iterate on different models and configurations. Gain valuable insights and make informed decisions to improve your ML workflows.
                        </p>
                    </div>
                </div>

                <div id='ApiDeploy' className='Api-type'>

                    <img className='ApiImg' src={Deploy} alt='' />

                    <div className='Api-type1'>
                        <h3>DEPLOY</h3>
                    
                        <p>
                            Execute machine learning workloads at scale, anywhere you need them.You can run your models in the cloud, on-premises, in a hybrid environment, or even at the edge. We provide flexibility , scalability and accessibility to suit your specific needs through reliable APIs.
                        </p>
                    </div>
                </div>

                <div id='ApiMonitor' className='Api-type'>

                    <img className='ApiImg' src={Monitor} alt='' />

                    <div className='Api-type2'>
                        <h3>MONITOR</h3>
                    
                        <p>
                            Ensure the performance and reliability of your deployed models with our platform comprehensive monitoring capabilities. Gain real-time insights into model behavior, resource utilization, and data quality. Receive alerts and notifications to proactively address any issues and optimize your ML deployments.
                        </p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Apis;