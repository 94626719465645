import { Component } from "react";
import Roles from "../../components/Roles";


export default class Role extends Component {

    render() {
        return (
            <div className="Role-page">
                <Roles/>
            </div>
        )
    }

}