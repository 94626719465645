import '../popup.css'
import React from "react";
import { Modal } from "reactstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";

function ITPopUp(props) {
  const modalStyles = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 100,
    width: window.innerWidth <= 790 ? "80%" : "auto",
  };

  const handleClose = () => {
    props.onClose();
  };

  return (
    <>
      <Modal isOpen={true} style={modalStyles} toggle={handleClose} backdrop={true}>
        <div className="PopUp-Header">
          <h2>IT ARCHITECTS</h2>
          <AiOutlineCloseCircle className='Close-ButtonPopup' onClick={props.onClose} />
        </div>

        <div className="PopUp-Body">
          <p>
            For IT architects, our solution offers a scalable and flexible infrastructure that adapts to the organization's needs. 
            <br/>
            <br/>
            We provide deployment options in both cloud and on-premises environments, allowing for hybrid or fully cloud-based implementation, depending on specific requirements. 
            <br/>
            <br/>
            Our platform also offers security options and access control, ensuring the protection of sensitive data and models. 
            <br/>
            <br/>
            Additionally, our solution integrates with existing tools and technologies, facilitating adoption and integration into the organization's IT ecosystem.
          </p>
        </div>
      </Modal>
    </>
  );
}

export default ITPopUp;
