import Container from 'react-bootstrap/Container';
import './navbar.css'
import React, { useEffect, useRef, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../../assets/images/NewLogoTransparent.png';
import EngineerPopUp from '../Popup/Engineer';
import DeveloperPopUp from '../Popup/Developer';
import ScientistPopUp from '../Popup/Scientist';
import ExecutivesPopUp from '../Popup/Executives';
import ArchitectsPopUp from '../Popup/ItArchitects'

function _NavBar() {

  const [showEngineerPopup, setEngineerShowPopup] = useState(false);
  const [showDeveloperPopup, setDeveloperShowPopup] = useState(false);
  const [showScientistPopup, setScientistShowPopup] = useState(false);
  const [showExecutivesPopup, setExecutivesShowPopup] = useState(false);
  const [showArchitectsPopup, setArchitectsShowPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleEngineerPopup = () => {
    setEngineerShowPopup(!showEngineerPopup);
  };

  const toggleDeveloperPopup = () => {
    setDeveloperShowPopup(!showDeveloperPopup);
  };

  const toggleScientistPopup = () => {
    setScientistShowPopup(!showScientistPopup);
  };

  const toggleExecutivesPopup = () => {
    setExecutivesShowPopup(!showExecutivesPopup);
  };

  const toggleArchitectsPopup = () => {
    setArchitectsShowPopup(!showArchitectsPopup);
  };

  const [ clicked, setClicked ] = useState(false);
  const [currentSection, setCurrentSection] = useState("Home");

  //---------- NAVBAR SECTION INDICATOR ----------

  useEffect(() => {
    
    const handleScroll = () => {
      
      const homeSection = document.getElementById("Home");
      const productSection = document.getElementById("ProductPage");
      const solutionSection = document.getElementById("SolutionPage");
      const apiSection = document.getElementById("ApiPage");
      const integrationSection = document.getElementById("IntegrationsPage");
      const whoSection = document.getElementById("whoPage");
      const contactSection = document.getElementById("contactPage");

      if (
        window.pageYOffset >= homeSection.offsetTop &&
        window.pageYOffset < productSection.offsetTop
      ){
        setCurrentSection("Home");
      }else if (
        window.pageYOffset >= productSection.offsetTop &&
        window.pageYOffset < solutionSection.offsetTop 
      ){
        setCurrentSection("ProductPage")
      }else if(
        window.pageYOffset >= solutionSection.offsetTop &&
        window.pageYOffset < apiSection.offsetTop
      ){
        setCurrentSection("SolutionPage")
      }else if (
        window.pageYOffset >= apiSection.offsetTop &&
        window.pageYOffset < integrationSection.offsetTop
      ){  setCurrentSection("ApiPage")
      }else if (
        window.pageYOffset >= integrationSection.offsetTop &&
        window.pageYOffset < whoSection.offsetTop
      ){
        setCurrentSection("IntegrationsPage")
      }else if (
        window.pageYOffset >= whoSection.offsetTop &&
        window.pageYOffset < contactSection.offsetTop
      ){
        setCurrentSection("whoPage")
      }else if(
        window.pageYOffset >= contactSection.offsetTop
      ){
        setCurrentSection("contactPage")
      }
        
    };

    window.addEventListener("scroll", handleScroll);
    
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, []);


  //---------- CLOSE MENU ON CLICK OUTSIDE ----------

  const navbarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        navbarRef.current &&
        !navbarRef.current.contains(event.target) &&
        document.documentElement.clientWidth <= 991
      ) {
        setClicked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  //---------- SCROLL TO ----------

  const handleClick = () => {
    setClicked(!clicked)
  }

  const scrollToHome = () => {
    scroll.scrollTo(document.getElementById("Home").offsetTop);
  };

  const scrollToProject = () => {
    scroll.scrollTo(document.getElementById("ProductPage").offsetTop);
  }

  const scrollToSolution = () => {
    scroll.scrollTo(document.getElementById("SolutionPage").offsetTop);
  }

  const scrollToApi = () => {
    scroll.scrollTo(document.getElementById("ApiPage").offsetTop);
  };

  const scrollToIntegrations = () => {
    scroll.scrollTo(document.getElementById("IntegrationsPage").offsetTop);
  };

  const scrollToWho = () => {
    scroll.scrollTo(document.getElementById("whoPage").offsetTop);
  };

  const scrollToContact = () => {
    scroll.scrollTo(document.getElementById("contactPage").offsetTop);
  };

  const handleClickHome = () => {
    scrollToHome();
  }

  const handleClickProduct = () => {
    scrollToProject();
    setMenuOpen(false);
  };

  const handleClickEngineerSolution = () => {
    scrollToSolution();
    toggleEngineerPopup();
    setMenuOpen(false);
  }

  const handleClickScientistSolution = () => {
    scrollToSolution();
    toggleScientistPopup();
    setMenuOpen(false);
  }

  const handleClickDeveloperSolution = () => {
    scrollToSolution();
    toggleDeveloperPopup();
    setMenuOpen(false);
  }

  const handleClickExecutivesSolution = () => {
    scrollToSolution();
    toggleExecutivesPopup();
    setMenuOpen(false);
  }

  const handleClickITSolution = () => {
    scrollToSolution();
    toggleArchitectsPopup();
    setMenuOpen(false);
  }

  const handleClickApi = () => {
    scrollToApi();
    setMenuOpen(false);
  }

  const handleClickIntegrations = () => {
    scrollToIntegrations();
    setMenuOpen(false);
  }

  const handleClickWho = () => {
    scrollToWho();
    setMenuOpen(false);
  }

  const handleClickContact = () => {
    scrollToContact();
    setMenuOpen(false);
  }



  return (
    <Navbar bg="light" expand="lg" className='navbar-custom' ref={navbarRef}>
      <img onClick={handleClickHome} className='Navbar-logo' alt='' src={ Logo } />
      <Container>
        <Navbar.Toggle onClick={() => setMenuOpen(!menuOpen)} aria-controls="basic-navbar-nav" className='Toggle'/>
        <Navbar.Collapse in={menuOpen} id="basic-navbar-nav" expanded={menuOpen}>
          <Nav className="me-auto" style={{ fontSize:'75%'  }}>
            
            <Nav.Link 
              href="#home" 
              onClick={handleClickProduct}
              style={{ 
                color: '#2B2E30', 
              }} 
              className="product-link"
              aria-controls="basic-navbar-nav"
              >
                <p className={ currentSection === "ProductPage" ? "current" : "" }>PRODUCT</p>
            </Nav.Link>
            
            <NavDropdown
              className='Solution-Drop'
              title={
                <span
                  className={`SolutionNav ${currentSection === "SolutionPage" ? "current" : ""}`}
                  style={{ color: '#2B2E30', fontSize: '100%' }}
                >
                SOLUTION
              </span>}
              id="basic-nav-dropdown">
             
              <NavDropdown.Item 
                className='Section-items'
                style={{
                  'color': '#2B2E30', fontSize: '75%', 'text-align': 'center','font-weight': 'bold',
                  backgroundColor: selectedItem === "item2" ? "green" : "initial",
                }}
                href="#action/3.1" 
                onClick={handleClickEngineerSolution}>
                DATA ENGINEERS
                {showEngineerPopup && <EngineerPopUp onClose={toggleEngineerPopup} />}
              </NavDropdown.Item>
              

              <NavDropdown.Item 
                className='Section-items'
                style={{ 'color': '#2B2E30',fontSize: '75%','text-align': 'center', 'font-weight': 'bold',backgroundColor: selectedItem === "item2" ? "green" : "initial",}} 
                href="#action/3.2" 
                onClick={handleClickDeveloperSolution}>
                DEVELOPERS
                {showDeveloperPopup && <DeveloperPopUp onClose={toggleDeveloperPopup} />}
              </NavDropdown.Item>
             
              <NavDropdown.Item 
                className='Section-items'
                style={{ 'color': '#2B2E30',fontSize: '75%', 'text-align': 'center', 'font-weight': 'bold', backgroundColor: selectedItem === "item2" ? "green" : "initial",}} 
                href="#action/3.3" 
                onClick= {handleClickITSolution}>
                IT ARCHITECTS
                {showArchitectsPopup && <ArchitectsPopUp onClose={toggleArchitectsPopup} />}
              </NavDropdown.Item>
             
              <NavDropdown.Item 
                className='Section-items'
                style={{ 'color': '#2B2E30',fontSize: '75%', 'text-align': 'center', 'font-weight': 'bold', backgroundColor: selectedItem === "item2" ? "green" : "initial",}} 
                href="#action/3.3" 
                onClick={handleClickScientistSolution}>
                DATA SCIENTIST
                {showScientistPopup && <ScientistPopUp onClose={toggleScientistPopup} />}
              </NavDropdown.Item>


              <NavDropdown.Item 
                className='Section-items'
                style={{ 'color': '#2B2E30',fontSize: '75%', 'text-align': 'center', 'font-weight': 'bold', backgroundColor: selectedItem === "item2" ? "green" : "initial",}} 
                href="#action/3.4" 
                onClick={handleClickExecutivesSolution}>
                EXECUTIVES
                {showExecutivesPopup && <ExecutivesPopUp onClose={toggleExecutivesPopup} />}
              </NavDropdown.Item>
            
            </NavDropdown>
            
            <Nav.Link 
              style={{ 'color': '#2B2E30', }} 
              href="#home"
              onClick={ handleClickApi } 
              className='api-nav'>
                <p className={ currentSection === "ApiPage" ? "current" : "" }>API</p>
            </Nav.Link>

            <Nav.Link 
              style={{ 'color': '#2B2E30', }} 
              href="#home"
              onClick={handleClickIntegrations} 
              className='integration-nav'>
                <p className={ currentSection === "IntegrationsPage" ? "current" : "" }>INTEGRATIONS</p>
            </Nav.Link>

            <Nav.Link 
              style={{ 'color': '#2B2E30',}} 
              href="#home"
              onClick={handleClickWho} 
              className='who-nav'>
                <p className={ currentSection === "whoPage" ? "current" : "" } style={{ 'display': 'inline' }}>WHO WE ARE</p>
            </Nav.Link>

          </Nav>
          <button className='contact-button' onClick={handleClickContact}>CONTACT</button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}



export default _NavBar;