import { Component } from "react";
import NavBar from "../../components/Navbar/index";


export default class Navbar extends Component {

    render() {
        return (
            <div>
                <NavBar/>
            </div>
        )
    }

}