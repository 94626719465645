import "./productcard.css";
import React from "react";
import Photo from '../../../assets/images/Solutions/execute.svg'

const Execute = () => {

    return(
        <div className="Productcard-container">

            <div className="Image-container">
                <img src={ Photo } alt='' style={{ width: '90%', 'margin-top': '0%', 'margin-right': '0%' }}/>
            </div>

            <div className="Productcard-information">
                <h3>EXECUTE</h3>
                <p>
                    Run machine learning models in real time, submit inference requests, obtain rapid results to make informed decisions and predictions.
                </p>
            </div>
        </div>
    )
};



export default Execute;