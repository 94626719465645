import { Component } from "react";
import Contact from "../../components/Contact";


export default class ContactPage extends Component {

    render() {
        return (
            <div>
               <Contact/> 
            </div>
        )
    }

}