import { Component } from "react";
import Landing from "../../components/Landing";


export default class Home extends Component {

    render() {
        return (
            <div id="Home" className="Home-page">
                <Landing />
            </div>
        )
    }

}