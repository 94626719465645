import "./landing.css";
import React from "react";
import Buttons from "../Buttons";
import { animateScroll as scroll } from "react-scroll";

const Landing = () => {

    const scrollToContact = () => {
        scroll.scrollTo(document.getElementById("contactPage").offsetTop);
      };

    return(
        <div className="Landing-container">
            <div className="information-container">
                <h1 className="Title">
                    <span>Maximize</span> the
                    <br/>
                    value of your
                    <br/>
                    Data & AI products
                </h1>

                <p>

                    With <span style={{ color: 'rgb(255, 159, 28)'}}>ConvolutionAI</span> organize, centralize, execute,
                    <br/>
                    industrialize and monitor your AI models. We 
                    <br/>
                    empower companies to overcome inconsistencies,
                    <br/> 
                    enhance productivity and maximize the value of their
                    <br/> 
                    Data & AI products.
                </p>
                <div onClick={scrollToContact} className="ButtonLanding">
                    <Buttons  buttonText="START TODAY"/>
                </div>
            </div>
        </div>
    )
};



export default Landing;