import { Component } from "react";
import WhoWeAre from "../../components/WhoWeAre";


export default class AboutPage extends Component {

    render() {
        return (
            <div id='whoPage'>
                <WhoWeAre/>   
            </div>
        )
    }

}