import '../popup.css'
import React from "react";
import { Modal } from "reactstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";

function ScientistPopUp(props) {
  const modalStyles = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 100,
    width: window.innerWidth <= 790 ? "80%" : "auto",
  };

  const handleClose = () => {
    props.onClose();
  };

  return (
    <>
      <Modal isOpen={true} style={modalStyles} toggle={handleClose} backdrop={true}>
        <div className="PopUp-Header">
          <h2>DATA SCIENTIST</h2>
          <AiOutlineCloseCircle className='Close-ButtonPopup' onClick={props.onClose} />
        </div>

        <div className="PopUp-Body">
          <p>
            Our platform is specifically designed to meet the needs of data scientists. 
            <br/>
            <br/>
            We offer a workspace where data scientists can explore and analyze data, experiment with different machine learning algorithms and techniques, and train and evaluate models.
            <br/>
            <br/>
            Additionally, we provide a wide range of data visualization and analysis tools, making it easier to understand and interpret results. 
            <br/>
            <br/>
            Our solution also enables collaboration and knowledge sharing among data scientists, fostering innovation and continuous improvement in the model creation process.
          </p>
        </div>
      </Modal>
    </>
  );
}

export default ScientistPopUp;
