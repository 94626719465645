import "./roles.css";
import React, { useState, useEffect } from "react";
import DataEngineer from "./Rolescard/data-engineer";
import Developer from "./Rolescard/developer";
import ItArchitects from "./Rolescard/it-architects";
import DataScientist from "./Rolescard/data-scientist";
import Executives from "./Rolescard/executives";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Rightarrow from "../../assets/images/Rightarrow.svg";

const Roles = () => {
  const [showSlider, setShowSlider] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setShowSlider(window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    prevArrow: <></>,
    nextArrow: <img className="slick-next" src={Rightarrow} alt="" />,
    responsive: [
      {
        breakpoint: 1024, // Tamaño de pantalla en el que se mostrarán 3 elementos
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Tamaño de pantalla en el que se mostrará 1 elemento
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div id="SolutionPage" className="Roles-Section">
      <div id="SolutionPage" className="Roles-container">
        <div className="Roles-title">
          <h2>Find the fit <span>for your role</span></h2>
        </div>
        {showSlider ? (
          <div className="Slider-container">
            <Slider {...sliderSettings}>
              <div className="RolesCards">
                <DataEngineer />
              </div>
              <div className="RolesCards">
                <Developer />
              </div>
              <div className="RolesCards">
                <ItArchitects />
              </div>
              <div className="RolesCards">
                <DataScientist />
              </div>
              <div className="RolesCards">
                <Executives />
              </div>
            
            </Slider>
          </div>
        ) : (
          <div className="RoleCards-container">
            <div className="RolesCards">
              <DataEngineer />
            </div>
            <div className="RolesCards">
              <Developer />
            </div>
            <div className="RolesCards">
              <ItArchitects />
            </div>
            <div className="RolesCards">
              <DataScientist />
            </div>
            <div className="RolesCards">
              <Executives />
            </div>
          </div>
        )}
        </div>
    </div>
  );
};

export default Roles;