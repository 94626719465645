import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/home';
import Product from './pages/product';
import Navbar from './pages/navbar';
import Role from './pages/role';
import ApiPage from './pages/apipage';
import IntegrationsPage from './pages/integrationspage';
import Whoweare from './pages/whowearepage/index'
import ContactPage from './pages/contactpage';

import Grilla from './pages/grilla';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Home/>
      <Product/>
      <Role/>
      <ApiPage/>
      <IntegrationsPage/>
      <Whoweare/>
      <ContactPage/>
      {/* <Grilla/> */}
    </div>
  );
}

export default App;
