import React, { useState } from "react";
import EngineerPopUp from "../../Popup/Engineer";

const DataEngineer = () => {
  
  const [showPopup, setShowPopup] = useState(false);
  const maxLength = 150;

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const paragraphText =
    "Our platform provides data engineers with the necessary tools to manage the entire pipeline of creating and automating machine learning models and datasets. With our solution, data engineers can access an intuitive and user-friendly interface that allows them to efficiently collect, clean, transform, and prepare data. We also offer integration options with different data sources and systems, making it easy to process and ingest large volumes of information. Additionally, our platform enables the automation of repetitive tasks and the scheduling of workflows, freeing up time for data engineers to focus on more strategic tasks.";

  const truncatedParagraph = truncateText(paragraphText, maxLength);


  return (
    <div className="Rolecard-container">
      <div className="Rolecard-information">
        <h3 className="Engineer-title">DATA ENGINEER</h3>
        <p>{truncatedParagraph}</p>
      </div>

      <button className="role-button" onClick={togglePopup}>
        learn more
      </button>

      {showPopup && <EngineerPopUp onClose={togglePopup} />}
    </div>
  );
};

export default DataEngineer;
