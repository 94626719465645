import "./products.css";
import React from "react";
import Industralize from "./Productscard/Industrialize";
import Test from "./Productscard/test";
import Monitor from "./Productscard/monitor";
import Train from "./Productscard/train";
import Versioning from "./Productscard/versioning";
import Centralize from "./Productscard/centralize";
import Execute from "./Productscard/execute";
import Deploy from "./Productscard/deploy";

const Products = () => {

    return(
        <div id="ProductPage"  className="Products-container">
            
            <div className="Product-title">
                <h1><span>Maximize</span> the value</h1>
                
                <p>Maximize the value of your AI investment with our powerful product features.</p>
            </div>

            <div className="Section-back">
                <div className="Section-container">
                    <div className="Sections-cards">
                        <Industralize/>
                        <Test/>
                        <Monitor/>
                        <Train/>
                        <Versioning/>
                        <Centralize/>
                        <Execute/>
                        <Deploy/>
                    </div>
                </div>
            </div>
        </div>
    )
};



export default Products;