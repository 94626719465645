import './apipage.css';
import { Component } from "react";
import Api from '../../components/ApiSection';


export default class ApiPage extends Component {

    render() {
        return (
            <div id='ApiPage' className="Api-Page">
                <Api/>
            </div>
        )
    }

}