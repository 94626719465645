import "./productcard.css";
import React from "react";
import Photo from '../../../assets/images/Solutions/inizalize.svg'

const Industralize = () => {

    return(
        <div className="Productcard-container">
            
            <div className="Image-container">
                <img src={ Photo } alt=''/>
            </div>
            
            <div className="Productcard-information">
                <h3>INDUSTRALIZE</h3>
                <p>Optimize and expand the deployment processes of your machine learning models in enterprise environments.</p>
            </div>
        </div>
    )
};



export default Industralize;