import { Component } from "react";
import Products from "../../components/Products";


export default class Product extends Component {

    render() {
        return (
            <div className="Product-page">
                <Products/>
            </div>
        )
    }

}