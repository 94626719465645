import '../popup.css';
import React from "react";
import { Modal } from "reactstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";

function DeveloperPopUp(props) {
  const modalStyles = {
    overflow: "hidden",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000000000,
    width: window.innerWidth <= 720 ? "80%" : "100%",
  };

  const handleClose = () => {
    props.onClose();
  };

  return (
    <>
      <Modal isOpen={true} style={modalStyles} toggle={handleClose} backdrop={true}>
        <div className="PopUp-Header">
          <h2>DEVELOPER</h2>
          <AiOutlineCloseCircle className="Close-ButtonPopup" onClick={handleClose} />
        </div>

        <div className="PopUp-Body">
          <p>
            For developers, our solution offers a collaborative and scalable environment that enables quick
            and easy implementation and deployment of machine learning models.
            <br />
            <br />
            Our platform provides popular machine learning libraries and frameworks, as well as{" "}
            development and testing tools.
            <br />
            <br />
            Developers can access pre-trained models and use them as a basis for their own applications,{" "}
            accelerating development time.
            <br />
            Furthermore, our solution facilitates the integration of models into existing systems through APIs
            and web services, allowing deployment in different environments and platforms.
          </p>
        </div>
      </Modal>
    </>
  );
}

export default DeveloperPopUp;
